import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, AccessRight } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -, -
/// </summary>

const NotiScanBankAccountDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const [bankOption, setBankOption] = useState([]);
  const { register, unregister, handleSubmit, errors } = useForm();
  const [bankId, setBankId] = useState("");
  const [xCustomAccountId, setXCustomAccountId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [status, setStatus] = useState("");
  const [notiScanId, setNotiScanId] = useState("");
  let urlEncodedStr = encodeURIComponent(btoa(AccessRight._NOTISCAN_BANKACCOUNT_PERMISSION));

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "bankId" }, { required: "PLEASE_SELECT_BANK" });
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_NOTISCAN_BANKACCOUNT_BY_ID;
      apiUrl += "?bankAccountId=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
          "Permission": urlEncodedStr
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setApiKey(responseJson[ApiKey._API_DATA_KEY]["apiKey"]);
            setApiSecret(responseJson[ApiKey._API_DATA_KEY]["apiSecret"]);
            setXCustomAccountId(responseJson[ApiKey._API_DATA_KEY]["xCustomAccountId"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setBankId(responseJson[ApiKey._API_DATA_KEY]["bankAccountId"]);
            setNotiScanId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            unregister("bankId");
          }
        });
    }

      fetch(ApiUrl._API_GET_TNG_BANK_LIST , {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        "Permission": urlEncodedStr
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bankList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
            bankList.push({ label: bank.accountNumber, value: bank.id });
          });

          setBankOption(bankList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      xCustomAccountId: data.xCustomAccountId,
      apiKey: data.apiKey,
      apiSecret: data.apiSecret,
      Status: data.status,
      bankAccountId: bankId,
    };
    if (!stringIsNullOrEmpty(notiScanId)) {
        params["id"] = notiScanId;
    }
    let formBody = createFormBody(params);

    _dispatch(setBusy());
    fetch(ApiUrl._API_CREATE_OR_UPDATE_NOTISCAN_BANK_ACCOUNT_DETAIL, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setNotiScanId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          }
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {bankId ? t("EDIT_BANK_ACCOUNT") : t("ADD_NEW_BANK_ACCOUNT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
              <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("BANK_ACCOUNT_INFORMATION")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("AVAILABLE_BANK")}</b>
                              </label>
                              <div className="col-md-11">
                                <Select
                                  name="bankId"
                                  options={bankOption}
                                  placeholder={
                                    bankOption.filter(
                                      (option) => option.value == bankId
                                    )[0] !== undefined
                                      ? bankOption.filter(
                                          (option) => option.value == bankId
                                        )[0].label
                                      : ""
                                  }
                                  value={bankOption.filter(
                                    (option) => option.value == bankId
                                  )}
                                  onChange={(e) => {
                                    unregister("bankId");
                                    setBankId(e.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("X_CUSTOM_ACCOUNT_ID")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "PLEASE_X_CUSTOM_ACCOUNT_ID"
                                  )}
                                  name="xCustomAccountId"
                                  defaultValue={xCustomAccountId}
                                  ref={register({
                                    required:
                                      "PLEASE_X_CUSTOM_ACCOUNT_ID",
                                  })}
                                />
                                {errors.xCustomAccountId && (
                                  <div className="invalid-feedback">
                                    {t(errors.xCustomAccountId.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("API_KEY")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "PLEASE_ENTER_API_KEY"
                                  )}
                                  defaultValue={apiKey}
                                  name="apiKey"
                                  ref={register({
                                    required:
                                      "PLEASE_ENTER_API_KEY",
                                  })}
                                />
                                {errors.apiKey && (
                                  <div className="invalid-feedback">
                                    {t(errors.apiKey.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("API_SECRET")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="apiSecret"
                                  defaultValue={apiSecret}
                                  placeholder={t("PLEASE_ENTER_API_SECRET")}
                                  ref={register({
                                    required: "PLEASE_ENTER_API_SECRET",
                                  })}
                                />
                                {errors.apiSecret && (
                                  <div className="invalid-feedback">
                                    {t(errors.apiSecret.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label">
                                    <b>{t("STATUS")}</b>
                                </label>
                              <div className="col-md-11">
                                    <div className="switcher">
                                        <input
                                            type="checkbox"
                                            name="status"
                                            id="status"
                                            onChange={(e) => setStatus(e.target.checked)}
                                            value={true}
                                            checked={status}
                                            ref={register}
                                        />
                                        <label htmlFor="status"></label>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default NotiScanBankAccountDetail;
