import React from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { createFormBody } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageRecurringJob = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();

    const StartRecurringJob = (jobName) => {
        _dispatch(setBusy);
        let apiUrl = ApiUrl._API_EXECUTE_RECURRING_JOB;
        let params = {
            "jobName": jobName
        };
        let formBody = createFormBody(params);
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t("OPERATION_SUCCESS")
                        )
                    )
                }
            });
        _dispatch(setSaveAdminLog("CREATE_RECURRING_JOB", _history.location.pathname, params));
        _dispatch(setIdle);
    }
    return (
        <div>
            <h1 className="page-header">
                {t("RECURRING_JOBS")}
            </h1>
            <Panel>
                <PanelBody>
                    <table style={{ width: "100%", border:"1px solid #d6dadd"}}>
                        <tr style={{borderBottom:"1px solid #a7aeb5"}}>
                            <th style={{borderRight: "1px solid #d6dadd", height:"60px", paddingLeft:"15px" }}>{t("JOB_TITLE")}</th><th>   </th>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckTransactionQueue      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckTransactionQueue")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckPendingTransaction      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color:"white" }} onClick={() => StartRecurringJob("CheckPendingTransaction")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>VipProcessing                </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("VipProcessing")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetJackpotWinner             </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetJackpotWinner")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CronJobGetWinLossReport      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CronJobGetWinLossReport")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process568ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process568ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process568SportsProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process568SportsProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPTCProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPTCProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPragmaticPlayGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPragmaticPlayGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessArionProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessArionProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>M8Settlement  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("M8Settlement")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>M8TicketTranslate  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("M8TicketTranslate")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessM8ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessM8ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessJClub777ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessJClub777ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process93ConnectProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process93ConnectProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessJJProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessJJProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessCTProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessCTProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CTTicketRecording  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CTTicketRecording")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessMega888ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessMega888ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessDGProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessDGProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>DGTicketRecording  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("DGTicketRecording")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessXEProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessXEProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessLionKingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessLionKingProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAdvantPlayProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAdvantPlayProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAllBetProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAllBetProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessVPowerProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessVPowerProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessTFGamingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessTFGamingProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAce333ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAce333ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessMarioClubGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessMarioClubGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process918KissGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process918KissGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPussy888KissGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPussy888KissGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessBooongoGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessBooongoGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAllBetProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAllBetProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessTFGamingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessTFGamingProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAce333ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAce333ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ReSettle568ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ReSettle568ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Reward Schedule Check  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckRewardSchedule")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Reward Daily Process </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("RewardDailyProcess")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Check If Completed Bets Are Pending </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckIfCompletedBetsArePending")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Generate Loyalty </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GenerateLoyalty")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Generate Birthday Announcement </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GenerateBirthdayAnnouncement")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>FarmWMGameRecord </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("FarmWMGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SettleWMProductGameRecord </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SettleWMProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>UpdateWMValidTurnover </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("UpdateWMValidTurnover")}>{t("START")}</button></td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>WCasinoSettlement </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("WCasinoSettlement")}>{t("START")}</button></td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>WCasinoRemoveBets </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("WCasinoRemoveBets")}>{t("START")}</button></td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>M365UserReport </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("M365UserReport")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>NextSpinTicketRecording  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("NextSpinTicketRecording")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetDailyProfitLossRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetDailyProfitLossRecord")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckPendingTransactionPaymentGateway </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckPendingTransactionPaymentGateway")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckYeeBetStatus </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckYeeBetStatus")}>{t("START")}</button></td>
                        </tr>
                         <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>RefreshHmsAccessToken </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("RefreshHmsAccessToken")}>{t("START")}</button></td>
                         </tr>
                         <tr>
                           <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>WcasinoSettleSpecialGame </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("WcasinoSettleSpecialGame")}>{t("START")}</button></td>
                         </tr>
                         <tr>
                           <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Update All Game List </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("UpdateAllGameList")}>{t("START")}</button></td>
                         </tr>
                         <tr>
                           <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>AsiaGamingGetOrder </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("AsiaGamingGetOrder")}>{t("START")}</button></td>
                          </tr>
                          <tr>
                <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SABAGamingResubmitProcess </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SABAGamingResubmitProcess")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetSportMatches  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetSportMatches")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetLaunchDomain  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetLaunchDomain")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                           <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>AsiaGamingGetOrder </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("AsiaGamingGetOrder")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SABAGamingResubmitProcess </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SABAGamingResubmitProcess")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SABASportRetryoperation  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SABASportRetryoperation")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SABASportGetReachLimitTrans  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SABASportGetReachLimitTrans")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckAndUpdateAutoWithdrawalTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckAndUpdateAutoWithdrawalTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckAndUpdateVPDepositTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckAndUpdateVPDepositTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckAndUpdateVPQRDepositTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckAndUpdateVPQRDepositTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckAndUpdateFPDepositTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckAndUpdateFPDepositTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SABATicketTranslate  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SABATicketTranslate")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetMegatotoJackpotValue  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetMegatotoJackpotValue")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                          <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetMega3DInfo  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetMega3DInfo")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>UpdateFunkyFishingVT  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("UpdateFunkyFishingVT")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Perdana3DSettlement  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Perdana3DSettlement")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Settle28WinVIPTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Settle28WinVIPTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Settle28WinVIP1Ticket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Settle28WinVIP1Ticket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Settle28Win4DTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Settle28Win4DTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Settle28WinGDTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Settle28WinGDTicket")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckAndUpdateWinPayTransaction  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckAndUpdateWinPayTransaction")}>{t("START")}</button></td>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>SettleBolakk4DTicket  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("SettleBolakk4DTicket")}>{t("START")}</button></td>
                        </tr>
                        </table>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageRecurringJob;
